import React from 'react';
import { Main, History, Options, Info } from './Game.styles';
import { calculateWinner } from '../helpers';
import Board from './Board';
import useLocalStorageState from '../utils/useLocalStorageState';

const Game = () => {
  const [history, setHistory] = useLocalStorageState('history', [
    Array(9).fill(null),
  ]);
  const [stepNumber, setStepNumber] = useLocalStorageState('step', 0);
  const [xIsNext, setXisNext] = useLocalStorageState('xIsNext', true);

  const winner = calculateWinner(history[stepNumber]);

  const handleClick = (i) => {
    const timeInHistory = history.slice(0, stepNumber + 1);
    const current = timeInHistory[stepNumber];
    const squares = [...current];

    if (winner || squares[i]) return;

    squares[i] = xIsNext ? 'X' : 'O';

    setHistory([...timeInHistory, squares]);
    setStepNumber(timeInHistory.length);
    setXisNext(!xIsNext);
  };

  const jumpTo = (step) => {
    setStepNumber(step);
    setXisNext(step % 2 === 0);
  };

  const renderMoves = () =>
    history.map((_step, move) => {
      const destination = move
        ? `Go to move #${move}`
        : winner
        ? 'Start New Game!'
        : 'Go To Start';

      return (
        <History key={move}>
          <Options onClick={() => jumpTo(move)}>{destination}</Options>
        </History>
      );
    });

  return (
    <Main>
      <Board squares={history[stepNumber]} onClick={handleClick} />
      <div>
        <Info>
          {winner
            ? `And the winner is... ${winner} !!!`
            : 'Is your turn, ' + (xIsNext ? 'X' : 'O')}
        </Info>
        {renderMoves()}
      </div>
    </Main>
  );
};

export default Game;
