import styled from '@emotion/styled';

export const BoardTable = styled.div`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  border-radius: 10px;
  width: 300px;
  height: 300px;
  margin: 0 auto;

  button:nth-child(1),
  button:nth-child(2),
  button:nth-child(3) {
    border-top: none;
  }

  button:nth-child(1),
  button:nth-child(4),
  button:nth-child(7) {
    border-left: none;
  }

  button:nth-child(7),
  button:nth-child(8),
  button:nth-child(9) {
    border-bottom: none;
  }

  button:nth-child(3),
  button:nth-child(6),
  button:nth-child(9) {
    border-right: none;
  }
`;
