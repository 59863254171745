import styled from '@emotion/styled';

export const Button = styled.button`
  font-family: 'Permanent Marker', cursive;
  background: white;
  border: 2px solid darkblue;
  font-size: 3em;
  font-weight: 800;
  cursor: pointer;
  outline: none;
`;
