import styled from '@emotion/styled';

export const Main = styled.div`
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const History = styled.li`
  list-style: none;
  padding: 0.25em;
`;

export const Options = styled.div`
  border: none;
  background-color: #6bbe92;
  width: 302px;
  padding: 10px 0;
  margin: 5px 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
`;

export const Info = styled.p`
  text-align: center;
`;
