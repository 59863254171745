import React from 'react';
import Square from './Square';
import { BoardTable } from './Board.styles';

const Board = ({ squares, onClick }) => (
  <BoardTable>
    {squares.map((square, i) => (
      <Square key={i} value={square} onClick={() => onClick(i)} />
    ))}
  </BoardTable>
);

export default Board;
